import { FormattedMessage } from 'react-intl'
import { useMemo } from 'react'

import AddToCart from 'src/components/AddToCart/AddToCart'
import FavoriteButton from 'src/components/FavoriteButton/FavoriteButton'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import Tag from 'src/components/Tag/Tag'
import { AinoProduct } from 'src/apollo/types'
import { CentraProduct } from 'src/types'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import { getCentraSrcSet } from 'src/utils/image'

import styles from './ListProduct.module.scss'

import type { Maybe } from 'src/apollo/types'

type Props = {
  product: AinoProduct | CentraProduct
  centraProduct?: CentraProduct
  onClick?: any
  isSkinType?: boolean
  ingredientProduct?: boolean
}

const ListProduct = ({
  product,
  centraProduct,
  onClick,
  isSkinType,
  ingredientProduct,
}: Props): JSX.Element => {
  const {
    categories,
    items,
    media,
    name,
    price,
    priceAsNumber,
    priceBeforeDiscount,
    primaryImage,
    showAsOnSale,
    sku,
    productTags,
  } = product

  const hoverImage =
    media && Array.isArray(media) && media.length > 1 && media[1]
      ? media[1]
      : null
  const clonedProd: any = { ...product }
  const slug = clonedProd.slug ? clonedProd.slug : clonedProd.uri
  let stock = null

  if (centraProduct && centraProduct.items && centraProduct.items[0].stock) {
    stock = centraProduct.items[0].stock
  } else if (items && items[0]) {
    stock = items[0].stock
  } else {
    stock = '0'
  }

  const productTagArray = centraProduct?.ainoProductTags || productTags

  return (
    <Box
      className={[
        styles.card,
        isSkinType ? styles.skinTypeProduct : '',
        ingredientProduct ? styles.ingredientProduct : '',
      ].join(' ')}
    >
      <LinkComponent
        onClick={() => {
          if (onClick) {
            onClick()
          }
        }}
        href={`/product/${slug}`}
      >
        <Flex flexDirection="column" width={1}>
          {productTagArray && productTagArray.length > 0 && (
            <Box as="span" className={styles.tag}>
              {productTagArray.map(
                (tag: Maybe<string>) =>
                  tag && <Tag size="small" title={tag} key={tag} />
              )}
            </Box>
          )}
          <Box
            marginBottom={[5, null, null, isSkinType ? 4 : 5]}
            className={styles.imageWrapper}
          >
            {primaryImage && (
              <Image
                sizes={{ mobile: '200vw', tablet: '200vw', desktop: '100vw' }}
                srcSet={getCentraSrcSet(primaryImage)}
                alt={name ? name : ''}
              />
            )}
            {hoverImage && (
              <Image
                className={styles.hoverImage}
                srcSet={getCentraSrcSet(hoverImage)}
                alt={name ? name : ''}
              />
            )}
            <Flex
              paddingRight={[4, null, null, isSkinType ? 4 : 6]}
              paddingBottom={[4, null, null, isSkinType ? 4 : 6]}
              className={styles.buttonWrapper}
            >
              <FavoriteButton small product={product} />
              {items && items[0] && (
                <AddToCart
                  small
                  item={
                    (centraProduct?.items &&
                      centraProduct.items?.length > 0 &&
                      centraProduct?.items[0]) ||
                    items[0]
                  }
                  product={{
                    ...centraProduct,
                    name: name || '',
                    sku: sku || '',
                    ...(categories && { categories }),
                  }}
                />
              )}
            </Flex>
          </Box>
          <Flex width={1} flexDirection="column">
            <Box
              className={styles.name}
              marginBottom={[1, null, null, isSkinType ? '2px' : 1]}
              as="span"
            >
              {name}
            </Box>
            {stock && parseInt(stock) > 0 ? (
              <Flex
                className={[
                  styles.priceWrapper,
                  centraProduct ? styles.loaded : null,
                ].join(' ')}
              >
                {(centraProduct?.showAsOnSale || showAsOnSale) && (
                  <Box marginRight={2} className={styles.old} as="span">
                    {centraProduct?.priceBeforeDiscount || priceBeforeDiscount}
                  </Box>
                )}
                <Box
                  className={
                    centraProduct?.showAsOnSale || showAsOnSale
                      ? styles.sale
                      : styles.price
                  }
                  as="span"
                >
                  {centraProduct?.price || price}
                </Box>
              </Flex>
            ) : (
              <Flex className={styles.outOfStock}>
                <FormattedMessage
                  defaultMessage="Out of stock"
                  id="RGPYN3"
                  description="Text when product is out of stock"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </LinkComponent>
    </Box>
  )
}

export default ListProduct
