import { GetStaticProps } from 'next'
import { useEffect } from 'react'
import Head from 'next/head'

import Hero from 'src/components/Hero/Hero'
import ModuleArticlesListing from 'src/components/Modules/ModuleArticlesListing/ModuleArticlesListing'
import ModuleHighlights from 'src/components/Modules/ModuleHighlights/ModuleHighlights'
import ModuleLogoSlider from 'src/components/Modules/ModuleLogoSlider/ModuleLogoSlider'
import ModuleProductList from 'src/components/Modules/ModuleProductList/ModuleProductList'
import ModuleQuote from 'src/components/Modules/ModuleQuote/ModuleQuote'
import SEO from 'src/components/SEO/SEO'
import { Flex } from 'src/components/UI/Grid/Grid'
import { GET_STARTPAGE } from 'src/apollo/queries'
import { apolloClient } from 'src/apollo/client'

import type { ContentfulModule, ContentfulStartpage } from 'src/apollo/types'

const StartPage = ({ page }: { page: ContentfulStartpage }): JSX.Element => {
  const handleScroll = () => {
    if (window.scrollY > 200) {
      document.body.classList.add('scrolled')
    }
    if (window.scrollY <= 200) {
      document.body.classList.remove('scrolled')
    }
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener('scroll', handleScroll)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const { hero, body } = page
  return (
    <Flex width={1} flexDirection="column">
      <SEO canonical="/" />
      <Head>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
                            window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
                            window._adftrack.push({
                                HttpHost: 'track.adform.net',
                                pm: 3634525,
                                divider: encodeURIComponent('|'),
                                pagename: encodeURIComponent('Landing Page')
                            });
                            (function () {
                                var s = document.createElement('script');
                                s.type = 'text/javascript';
                                s.async = true;
                                s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js';
                                var x = document.getElementsByTagName('script')[0];
                                x.parentNode.insertBefore(s, x);
                            })();
                        `,
          }}
        />
        <noscript>
          <p style={{ margin: 0, padding: 0, border: 0 }}>
            <img
              src="https://track.adform.net/Serving/TrackPoint/?pm=3634525&ADFPageName=Landing%20Page&ADFdivider=|"
              width="1"
              height="1"
              alt=""
            />
          </p>
        </noscript>
      </Head>
      {hero && <Hero {...hero} />}
      {body &&
        body.length > 0 &&
        body.map((section: ContentfulModule | null, index: number) => {
          if (section && section.type === 'moduleQuote') {
            return <ModuleQuote key={index} {...section} />
          }
          if (section && section.type === 'moduleProductList') {
            return <ModuleProductList key={index} {...section} />
          }
          if (section && section.type === 'moduleHighlightList') {
            return <ModuleHighlights key={index} {...section} />
          }
          if (section && section.type === 'moduleBlogListing') {
            return (
              <ModuleArticlesListing
                key={index}
                background="white"
                articles={section.blogPosts}
                title={section.title}
              />
            )
          }
          if (section && section.type === 'moduleLogoSlider') {
            return <ModuleLogoSlider key={index} {...section} />
          }
        })}
    </Flex>
  )
}

export default StartPage

export const getStaticProps: GetStaticProps = async (args) => {
  const { preview, locale, defaultLocale } = args
  const props: any = {}
  const result = await apolloClient(preview ? preview : false).query({
    query: GET_STARTPAGE(locale ? locale : defaultLocale ? defaultLocale : ''),
  })

  if (
    result &&
    result.data &&
    result.data.contentfulStartpages &&
    result.data.contentfulStartpages.entries &&
    result.data.contentfulStartpages.entries.length > 0
  ) {
    props.page = result.data.contentfulStartpages.entries[0]
  }

  return { props }
}
